<template>
    <v-row class="ma-0 height-100">
      <v-col cols="6">

        <div>
        <p class="primary--text mb-0">{{$t('BANNER_EDITOR.SIZE')}}</p>
          <v-row>
            <v-col cols="3">
              <small>{{$t('BANNER_EDITOR.WIDTH')}}</small>
              <v-text-field type="number" min="10" max="1280" outlined hide-details dense v-model="content.width"/>
            </v-col>
            <v-col cols="1" class="text-center">
              <p class="mt-8">x</p>
            </v-col>
            <v-col cols="3">
              <small>{{$t('BANNER_EDITOR.HEIGHT')}}</small>
              <v-text-field type="number" min="10" max="640" outlined hide-details dense v-model="content.height" />
            </v-col>
            <v-col cols="5">
              <p class="mt-8">pixels</p>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mt-6 mb-6" />

        <p class="primary--text">{{$t('BANNER_EDITOR.PARAMS')}}&nbsp;<v-btn class="secondary black--text" depressed x-small @click="putExampleData">{{$t('BANNER_EDITOR.EXAMPLE_DATA')}}</v-btn></p>
        <v-tabs v-model="tab">
          <v-tab>HTML</v-tab>
          <v-tab>Script</v-tab>
          <v-tab>Style</v-tab>
          <v-tab>Macros <v-chip x-small :class="['ml-2', macrosInHtml.length > 0 ? 'success' : 'grey lighten-2']">{{macrosInHtml.length}}</v-chip></v-tab>
          <v-spacer />
          <v-tab>{{$t('BANNER_EDITOR.ADVANCED')}}</v-tab>
        </v-tabs>
        <v-divider />


        <v-tabs-items v-model="tab" class="pa-4" slider-color="accent" :eager="true">

          <v-tab-item transition="none">
            <v-textarea
            outlined
            dense
            hide-details
            label="Template (html)"
            height="32vh"
            v-model="content.html"
            :rules="[$rules.required]"
            />
          </v-tab-item>

          <v-tab-item transition="none">
            <v-textarea
            outlined
            dense
            hide-details
            label="Script (javascript)"
            height="32vh"
            v-model="content.script"
            />
          </v-tab-item>

          <v-tab-item transition="none">
            <v-textarea
            outlined
            dense
            hide-details
            label="Style (css)"
            height="32vh"
            v-model="content.style"
            />
          </v-tab-item>

          <v-tab-item transition="none">
            <div v-if="macrosInHtml.length === 0" class="text-center">
              <small class="grey--text">{{$t('BANNER_EDITOR.NO_MACRO')}}</small>
            </div>
            <div v-else v-for="(macro, i) in macrosInHtml" :key="'ma'+i" class="mb-4">
              <v-text-field
              outlined
              dense
              hide-details
              :label="macro"
              v-model="content[macro]"
              :rules="[$rules.required]"
              />
            </div>
          </v-tab-item>

          <v-tab-item transition="none">
            <div class="text-center">
              <small class="grey--text">{{$t('BANNER_EDITOR.NO_ADVANCED')}}</small>
            </div>
            <!-- <div v-else v-for="(macro, i) in macrosInHtml" :key="'ma'+i" class="mb-4">
              <v-text-field
              outlined
              dense
              hide-details
              :label="macro"
              v-model="content[macro]"
              :rules="[$rules.required]"
              />
            </div> -->
          </v-tab-item>

        </v-tabs-items>

        <!--

        <div>{{$t('BANNER_EDITOR.MACROS_IN_HTML')}}</div>
        <div v-if="(macrosInHtml || []).length === 0">
          <small class="grey--text">{{$t('BANNER_EDITOR.NO_MACRO')}}</small>
        </div>
        <div v-else>
          <small class="grey--text">{{$t('BANNER_EDITOR.MACRO_RELATION')}}</small>
        </div>

        <v-divider class="mt-6 mb-6" />

        <div class="mb-3">
          <v-textarea
          outlined
          dense
          hide-details
          label="Template (html)"
          v-model="content.html"
          :rules="[$rules.required]"
          />
        </div>
        <div class="mb-3">
          <v-textarea
          outlined
          dense
          hide-details
          label="Style (css)"
          v-model="content.style"
          />
        </div>
        <div class="mb-3">
          <v-textarea

        {{content}}
          outlined
          dense
          hide-details
          label="Script (javascript)"
          v-model="content.script"
          />
        </div> -->

      </v-col>
      <v-divider vertical />
      <v-col cols="6" class="d-flex flex-column align-center pt-10 grey lighten-3">
        <p class="grey--text"><small><i>{{$t('BANNER_EDITOR.BANNER_EXAMPLE')}}</i></small></p>
        <!-- {{content}} -->
        <div class="d-inline-block white elevation-4" :style="{width:ratio_width+'px', height:ratio_height+'px', transform:'scale(0.66)', overflow:'hidden'}">
          <BannerEditorPreview :values="{content}" />
          <!-- <div class="height-100 width-100" v-html="compiledHtml" /> -->
          <!-- <component :is="'style'">{{compiledStyle}}</component> -->
        </div>
        <!-- <div class="text-ellipsis text-nowrap mt-4" :style="{width:ratio_width/1.5+'px', minWidth:'200px'}"><small><strong>{{content.title}}</strong></small></div>
        <div class="text-ellipsis text-nowrap" :style="{width:ratio_width/1.5+'px', minWidth:'200px'}"><small>{{content.description}}</small></div> -->
      </v-col>
    </v-row>
</template>


<style scoped>

.iframe {
  width: 100%;
  height: 100%;
}

</style>

<script>

const htmlExample = ''
  + '<!DOCTYPE html>'
  + '<html>'
  + '<head>'
    + '<meta charset="utf-8">'
  + '</head>'
  + '<body>'
    + '<div class="test-banner">'
      + '<h3>$title</h3>'
      + '<h5>This is a dynamic macro !</h5>'
    + '</div>'
    + '</body>'
  + '</html>'

const styleExample = '.test-banner { display: flex; flex-direction:column; height:100%; width:100%; text-align: center; justify-content: center; align-items: center; background:url($image) center center; background-size:cover;}'
  + '\n'
  + '\n'
  + '.test-banner h3 { display:inline-block; padding: 10px; background: blue; color: #fff; animation: zoom 2s infinite; }'
  + '\n'
  + '\n'
  + '@keyframes zoom {'
  + '\n'
  + '0%{transform: scale(1) rotate(5deg);}'
  + '\n'
  + '50%{transform: scale(0.9) rotate(-5deg);}'
  + '\n'
  + '100%{transform: scale(1) rotate(5deg);}'
  + '\n'
  + '}'
import BannerEditorPreview from './BannerEditor.preview'
import InputImage from '@/components/InputImage'
export default {
  name:'BannerEditorHtml',
  components: {
    BannerEditorPreview,
    InputImage
  },
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  created () {
    this.attribValues()
  },
  data: () => ({
    tab: 0,
    images: {},

    //
    params: {
    },
    content: {
      width: 200,
      height: 200,
      html:'',
      style:'',
      script:'',
    }
  }),

  computed: {
    ratio () {
      return this.content.height<this.content.width ? this.content.height/this.content.width : this.content.width/this.content.height
    },
    ratio_width () {
      return (this.content.width)
    },
    ratio_height () {
      return (this.content.height)
    },
    image_formats () {
      return [
        {name: 'banner', height: this.content.height, width: this.content.width },
      ]
    },
    macrosInHtml () {
      let content = ''
      if (this.content.html) content += this.content.html
      if (this.content.style) content += this.content.style
      if (this.content.script) content += this.content.script
      if (content) {
        const regex = /(\$[a-z0-9]{1,30})/gmi
        return [...new Set(((content).match(regex)) || [])]
      }
      return []
    },
    // compiledHtml () {
    //   let content = ''
    //   if (this.content.html) {
    //     content = this.content.html.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.content[a] !== 'undefined') {
    //         return this.content[a]
    //       }
    //       return a//'<span class="yellow" title="'+ this.$i18n.t('BANNER_EDITOR.NO_VALUE')+'">' + a + '</span>'
    //     })
    //   }
    //   return content
    // },
    // compiledStyle () {
    //   let content = ''
    //   if (this.content.style) {
    //     content = this.content.style.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.content[a] !== 'undefined') {
    //         return this.content[a]
    //       }
    //       return a
    //     })
    //   }
    //   return content
    // },
    // compiledScript () {
    //   let content = ''
    //   if (this.content.script) {
    //     content = this.content.script.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.content[a] !== 'undefined') {
    //         return this.content[a]
    //       }
    //       return a
    //     })
    //   }
    //   return content
    // }
  },

  watch: {
    content: {
      handler (n) {
        // console.log('VALUES', n)
        this.$emit('input', this.$clone({
          content: this.$clone(this.content),
          params: this.$clone(this.params),
        }))
        // this.generateIframe()
      },
      deep: true
    }
  },

  methods: {
    // generateIframe () {
    //   const iframe = this.$refs.iframe
    //   let file = '<html>'
    //   file += '<head>'
    //   file += '<' + 'script>'+this.compiledScript+'<' + '/script>'
    //   file += '<style>html, body { margin: 0; padding: 0; overflow:hidden; height:100%; width:100%; }'+this.compiledStyle+'</style>'
    //   file += '</head>'
    //   file += '<body>' + this.compiledHtml+'</body>'
    //   file += '</html>'
    //   let blob = new Blob([file], { type: 'text/html' })
    //   const url = URL.createObjectURL(blob)
    //   iframe.src = url
    // },
    //
    // updateValues () {
    //   this.$emit('input', this.$clone({
    //     content: this.content,
    //     params: this.params,
    //     width: this.content.width,
    //     height: this.content.height,
    //   }))
    // },

    putExampleData () {
      this.content = {
        $test: 'HELLO !',
        $image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Tour_Eiffel_Wikimedia_Commons.jpg/260px-Tour_Eiffel_Wikimedia_Commons.jpg',
        html: htmlExample,
        style: styleExample,
        width: 120,
        height: 600,
      }
    },

    handleImage (images = {}) {
      this.images = images || {}
      console.log(images)
      if (images && images.banner) {
        this.content.image = images.banner
      }
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    },
  }

}
</script>
